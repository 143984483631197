import type { BlockType } from '$lib/graphql/types'
import type { GeoPointFieldsFragment } from '$lib/queries/fragments/generated/GeoPointFields'
import type { PanelType } from '$src/lib/utils/panel'

type ListItem = {
	clickAction: PanelType
	title: string
	iconUrl: string
	bgColor: string
	description: string
}

export const blockTypes: ListItem[] = [
	{
		clickAction: 'destination',
		title: 'Add a Destination',
		iconUrl: '/visuals/icons/flag.svg',
		bgColor: 'bg-pink-600',
		description: 'Add a destination (city or town) to your trip.',
	},
	{
		clickAction: 'highlight',
		title: 'Add a Highlight',
		iconUrl: '/visuals/icons/landscape-1.svg',
		bgColor: 'bg-yellow-600',
		description: 'Upload an image or video to explain the significance of a trip element.',
	},
	{
		clickAction: 'link',
		title: 'Bookmark a URL',
		iconUrl: '/visuals/icons/link-chain.svg',
		bgColor: 'bg-blue-600',
		description: 'Save a page from any site to your trip.',
	},
	{
		clickAction: 'list',
		title: 'Add a List',
		iconUrl: '/visuals/icons/paginate-heart.svg',
		bgColor: 'bg-green-600',
		description: 'Group multiple blocks together under one heading.',
	},
	{
		clickAction: 'place',
		title: 'Add a Place',
		iconUrl: '/visuals/icons/location-pin.svg',
		bgColor: 'bg-red-600',
		description: 'Add a place to your trip. For example, a restaurant, hotel, or attraction.',
	},
	{
		clickAction: 'pin',
		title: 'Drop a Pin',
		iconUrl: '/visuals/icons/pin-1.svg',
		bgColor: 'bg-purple-600',
		description: 'Add your custom location that may not have an address.',
	},
	{
		clickAction: 'region',
		title: 'Draw a Region',
		iconUrl: '/visuals/icons/select-all.svg',
		bgColor: 'bg-orange-600',
		description: 'Draw a region on a map to emphasize its significance. ',
	},
]

export function childBlocksByType(type: BlockType) {
	switch (type) {
		case 'DESTINATION':
			return blockTypes.filter((block) => block.clickAction !== 'destination')
		case 'DAY':
			return blockTypes.filter((block) => block.clickAction !== 'destination')
		case 'HIGHLIGHT':
			return []
		case 'SOURCE':
			return []
		case 'LIST':
			return blockTypes.filter(
				(block) => block.clickAction !== 'list' && block.clickAction !== 'destination',
			)
		case 'PLACE':
			return blockTypes.filter(
				(block) => block.clickAction === 'highlight' || block.clickAction === 'link',
			)
		case 'CUSTOM_PIN':
			return blockTypes.filter(
				(block) => block.clickAction === 'highlight' || block.clickAction === 'link',
			)
		case 'CUSTOM_REGION':
			return blockTypes.filter(
				(block) => block.clickAction === 'highlight' || block.clickAction === 'link',
			)
		default:
			return blockTypes
	}
}

export const proposedRegions = [
	'reg_2YHEOTicUCmBq44kSLDl3hme8Vk',
	'reg_2YJ4aBPL0Tj5t0UudhfzoxjcNjG',
	'reg_2YJ55RNnZyJ2z1zYt6C7L4V0vyy',
	'reg_2YHEOH4nSGBtp28XiSx3y9DnBQY',
	'reg_2YJ3xjQCy0ewzrSGGZJZmOQs27o',
	'reg_2YHBHN6QpZ5bjQAmgP8YSvR10up',
	'reg_2YJ440YsGgjFdLr86ZowrA7GvDq',
	'reg_2YJ0x81C4RGd67lSH4xMOmLdCoP',
	'reg_2YH5zaH1riz89Tb0KISKkoIf8dz',
	'reg_2YH60kfVQEvRy1dYFiiN9QHuTfC',
	'reg_2YJ3cYiws7j164lCWTDx2Y4vG2l',
	'reg_2YJ4GReKPNozJYOPkGzwKp7h1HA',
]

export const excludedTypes = ['route']

export type Prediction = google.maps.places.AutocompletePrediction

export type LocationItem = { value: string; label: string; description?: string }

export const defaultDestinations = [
	{ value: 'ChIJSyrkEAPUT48Rt5r_k9vA7Q4', label: 'Tulum, Mexico' },
	{ value: 'ChIJawhoAASnyhQR0LABvJj-zOE', label: 'İstanbul, Turkey' },
	{ value: 'ChIJD7fiBh9u5kcRYJSMaMOCCwQ', label: 'Paris, France' },
	{ value: 'ChIJP3Sa8ziYEmsRUKgyFmh9AQM', label: 'Sydney, NSW, Australia' },
	{ value: 'ChIJZZZY9GE40i0RMP2CyvsLAwU', label: 'Canggu, Bali, Indonesia' },
	{
		value: 'ChIJOwg_06VPwokRYv534QaPC8g',
		label: 'New York, NY, USA',
	},
	{ value: 'ChIJRcbZaklDXz4RYlEphFBu5r0', label: 'Dubai, United Arab Emirates' },
	{ value: 'ChIJEcHIDqKw2YgRZU-t3XHylv8', label: 'Miami, FL, USA' },
	{ value: 'ChIJXSModoWLGGARILWiCfeu2M0', label: 'Tokyo, Japan' },
	{
		value: 'ChIJQXiZwsjM0DAR1AMlknbhaWo',
		label: 'Chaing Mai, Thailand',
	},
	{ value: 'ChIJN90Pvwa_ohQRRHp75-tdqPo', label: 'Mýkonos, Greece' },
	{ value: 'ChIJLwPMoJm1RIYRetVp1EtGm10', label: 'Austin, TX, USA' },
	{ value: 'ChIJL6wn6oAOZ0gRoHExl6nHAAo', label: 'Dublin, Ireland' },
	{ value: 'ChIJD8MDkkA5no8Rk_jBLF6fGLs', label: 'Tamarindo, Costa Rica' },
	{
		value: 'ChIJVTPokywQkFQRmtVEaUZlJRA',
		label: 'Seattle, WA, USA',
	},
	{ value: 'ChIJOfBn8mFuQUYRmh4j019gkn4', label: 'Oslo, Norway' },
	{ value: 'ChIJn8o2UZ4HbUcRRluiUYrlwv0', label: 'Vienna, Austria' },
	{ value: 'ChIJF_W8a_xdNRMRmJ-0MfaPeaE', label: 'Split, Croatia' },
	{ value: 'ChIJyc_U0TTDQUcRYBEeDCnEAAQ', label: 'Budapest, Hungary' },
	{ value: 'ChIJya-ah6j_kT4RLKd5DW2HU9s', label: 'Muscat, Oman' },
]

// name, id, address, lat, lng
export const defaultDestinationsGeo = [
	{
		name: 'Cape Town',
		address: 'Cape Town, Westers Cape, South Africa',
		latitude: -33.928992,
		longitude: 18.417396,
		id: '51317bd976da6a324059711fb935e9f640c0f00103f901de97f20100000000c00207',
		countryCode: 'ZA',
	},
	{
		name: 'Tulum',
		address: 'Tulum, Quintana Roo, Mexico',
		latitude: 20.16945505,
		longitude: -87.449735171,
		id: '5195d30676c8dc55c0595de4f967612b3440f00101f9019a24620000000000c00207',
		countryCode: 'MX',
	},
	{
		name: 'Istanbul',
		address: 'Istanbul, Marmara Region, Turkey',
		latitude: 41.0091982,
		longitude: 28.9662187,
		id: '519c48d51b5af73c40594e1718682d814440f00103f901138f2e7000000000c00207',
		countryCode: 'TR',
	},
	{
		name: 'Paris',
		address: 'Paris, Ile-de-France, France',
		latitude: 48.8534951,
		longitude: 2.3483915,
		id: '5194a2957b81c9024059ece5d2533f6d4840f00101f9016517010000000000c00208',
		countryCode: 'FR',
	},
	{
		name: 'Sydney',
		address: 'Sydney, New South Wales, Australia',
		latitude: -33.8698439,
		longitude: 151.2082848,
		id: '512088e244aae662405903907f0b57ef40c0f00103f901f310d20000000000c002079203065379646e6579',
		countryCode: 'AU',
	},
	{
		name: 'Canggu',
		address: 'Canggu, Bali, Indonesia',
		latitude: -8.653312,
		longitude: 115.1321744,
		id: '5194579d8b75c85c4059302b14e97e4e21c0f00103f9019d45014e00000000c00207',
		countryCode: 'ID',
	},
	{
		name: 'New York',
		address: 'New York, New York, United States',
		latitude: 40.7127281,
		longitude: -74.0060152,
		id: '51d9d1938d628052c0595938a4ac3a5b4440f00101f90121af020000000000c00208',
		countryCode: 'US',
	},
	{
		name: 'Dubai',
		address: 'Dubai, Dubai, United Arab Emirates',
		latitude: 25.2653471,
		longitude: 55.2924914,
		id: '513eaeb25b70a54b4059a4969cc9ed433940f00103f9017ed0dc0100000000c00208',
		countryCode: 'AE',
	},
	{
		name: 'Miami',
		address: 'Miami, Florida, United States',
		latitude: 25.7741728,
		longitude: -80.19362,
		id: '517ff62345640c54c059e773493030c63940f00101f9010191120000000000c00208',
		countryCode: 'US',
	},
	{
		name: 'Tokyo',
		address: 'Tokyo, Japan',
		latitude: 35.6840574,
		longitude: 139.7744912,
		id: '5146e1c4a1c8786140591ccb60318fd74140f00101f901d58b170000000000c00208',
		countryCode: 'JP',
	},
	{
		name: 'Chiang Mai',
		address: 'Chiang Mai, Chiang Mai Province, Thailand',
		latitude: 18.7882778,
		longitude: 98.9858802,
		id: '51883144a918bf584059af29eb92ccc93240f00103f9011cec9c0a00000000c00206',
		countryCode: 'TH',
	},
	{
		name: 'Mykonos',
		address: 'Mykonos, Aegean, Greece',
		latitude: 37.4450377,
		longitude: 25.3259658,
		id: '514e9da27e7253394059587ecffef6b84240f00103f9013431b20300000000c002079203074d796b6f6e6f73',
		countryCode: 'GR',
	},
	{
		name: 'Austin',
		address: 'Austin, Texas, United States',
		latitude: 30.2711286,
		longitude: -97.7436995,
		id: '514da3c9c5986f58c059a10216af68453e40f00101f901a2ba010000000000c00208',
		countryCode: 'US',
	},
	{
		name: 'Dublin',
		address: 'Dublin, Leinster, Ireland',
		latitude: 53.3493795,
		longitude: -6.2605593,
		id: '51b3aca00ed00a19c0594832ab77b8ac4a40f00101f9011bee100000000000c00208',
		countryCode: 'IE',
	},
	{
		name: 'Tamarindo',
		address: 'Tamarindo, Costa Rica',
		latitude: 10.2984121,
		longitude: -85.8399505,
		id: '5191f0bdbfc17555c0591c768478c9982440f00101f90176d3610000000000c00208',
		countryCode: 'CR',
	},
	{
		name: 'Oslo',
		address: 'Oslo, Norway',
		latitude: 59.9133301,
		longitude: 10.7389701,
		id: '519671f8495a7a254059e9f92e00e8f44d40f00101f9014b32060000000000c00208',
		countryCode: 'NO',
	},
	{
		name: 'Vienna',
		address: 'Vienna, Austria',
		latitude: 48.2083537,
		longitude: 16.3725042,
		id: '51669f6c6f5c5f30405914c08355ab1a4840f00101f9016eaa010000000000c00208',
		countryCode: 'AT',
	},
	{
		name: 'Split',
		address: 'Split, Croatia',
		latitude: 43.5116383,
		longitude: 16.4399659,
		id: '51efdaef9aa170304059c6f0225d7dc14540f00101f9015d31aa0000000000c00208',
		countryCode: 'HR',
	},
	{
		name: 'Buenos Aires',
		address: 'Buenos Aires, Argentina',
		latitude: -34.6075682,
		longitude: -58.4370894,
		id: '51d136a38bf2374dc059788b76cbc44d41c0f00101f901ccaf120000000000c00208',
		countryCode: 'AR',
	},
	{
		name: 'Muscat',
		address: 'Muscat, Muscat Governorate, Oman',
		latitude: 23.5882019,
		longitude: 58.3829448,
		id: '512d16d05504314d4059f0f1536694963740f00101f901fd40d90000000000c00208',
		countryCode: 'OM',
	},
] as GeoPointFieldsFragment[]

export const defaultFeatures = [
	'locality',
	'administrative_area_level_1',
	'country',
	'administrative_area_level_2',
]

export const defaultPlaceFeatures = [
	'historical_landmark',
	'restaurant',
	'national_park',
	'art_gallery',
	'museum',
	'spa',
]
